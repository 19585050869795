<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else class="text-normal">
      <h4 class="header-tablepage">Ticket Field</h4>
      <b-tabs class="mt-3">
        <b-tab title="Form Setting" active>
          <b-row>
            <b-col sm="12" md="6" lg="4">
              <div class="header-secondary title-tabs header-title d-flex py-3">
                <b>Fields</b>
                <b-button class="add-btn" @click="addField">
                  <b-icon icon="plus-square" class="mr-2"></b-icon
                  ><span>Create New</span></b-button
                >
              </div>
              <draggable
                :list="fieldList"
                @end="changeDrag"
                class="question-panel"
              >
                <div
                  v-for="(item, index) of fieldList"
                  :key="'add-' + index"
                  @click="handleClickActive(true, index)"
                  :class="['question-card', index == activeIndex && 'active']"
                >
                  <div class="question-card-wrapper">
                    <div class="question-card-title">
                      <div class="text-black font-weight-bold line-clamp-1">
                        {{ item.label_admin || item.name }}:
                      </div>
                      <div class="type-box line-clamp-1">
                        {{ questionTypeText(item.field_type_id) }}
                      </div>
                    </div>
                    <div class="text-light-grey" v-if="item.is_default_field">
                      Default
                    </div>
                  </div>
                  <div>
                    <span class="text-light-grey line-clamp-1">
                      {{ labelDescription(item) }}
                    </span>
                  </div>
                </div>
              </draggable>
            </b-col>
            <b-col sm="12" md="6" lg="8">
              <div
                class="header-secondary title-tabs header-title align-items-center"
              >
                <b>Detail</b>
                <b-icon
                  v-if="!fieldList[activeIndex].is_default_field"
                  size="lg"
                  icon="trash-fill"
                  class="icon-size pointer"
                  @click="deleteQuestion"
                />
              </div>
              <div class="question-detail-wrapper">
                <b-row>
                  <b-col sm="12" md="12" lg="6">
                    <div class="text-black font-weight-bold mb-3">
                      For Admin
                    </div>
                    <b-form-checkbox
                      v-model="fieldList[activeIndex].is_require_submit"
                      :checked="fieldList[activeIndex].is_require_submit"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field) ||
                        [10, 11, 13, 14].includes(
                          fieldList[activeIndex].field_type_id
                        )
                      "
                      @change="
                        fieldList[activeIndex].is_require_submit = $event
                      "
                      class="mb-1"
                      >Required when submitting the ticket</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="fieldList[activeIndex].is_require_close"
                      :checked="fieldList[activeIndex].is_require_close"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field) ||
                        [10, 11, 13, 14].includes(
                          fieldList[activeIndex].field_type_id
                        )
                      "
                      class="mb-1"
                      @change="fieldList[activeIndex].is_require_close = $event"
                      >Required when closing the ticket</b-form-checkbox
                    >
                  </b-col>
                  <b-col sm="12" md="12" lg="6">
                    <div class="text-black font-weight-bold mb-3">
                      For Customers
                    </div>
                    <b-form-checkbox
                      v-model="fieldList[activeIndex].is_customer_view"
                      :checked="fieldList[activeIndex].is_customer_view"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field) ||
                        [10, 11, 13, 14].includes(
                          fieldList[activeIndex].field_type_id
                        )
                      "
                      @change="fieldList[activeIndex].is_customer_view = $event"
                      class="mb-1"
                      >Can View</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="fieldList[activeIndex].is_customer_edit"
                      :checked="fieldList[activeIndex].is_customer_edit"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field) ||
                        [10, 11, 13, 14].includes(
                          fieldList[activeIndex].field_type_id
                        )
                      "
                      class="mb-1"
                      @change="fieldList[activeIndex].is_customer_edit = $event"
                      >Can Edit</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="
                        fieldList[activeIndex].is_customer_require_submit
                      "
                      :checked="
                        fieldList[activeIndex].is_customer_require_submit
                      "
                      :value="1"
                      :unchecked-value="0"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field) ||
                        [10, 11, 13, 14].includes(
                          fieldList[activeIndex].field_type_id
                        )
                      "
                      class="mb-1"
                      @change="
                        fieldList[activeIndex].is_customer_require_submit =
                          $event
                      "
                      >Required when submitting the ticket</b-form-checkbox
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" lg="6">
                    <InputSelect
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                      ]"
                      textFloat="Choose a field type"
                      title="Field Type"
                      name="type"
                      isRequired
                      v-model="fieldList[activeIndex].field_type_id"
                      v-bind:options="fieldTypes"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field)
                      "
                      @onDataChange="changeFieldType"
                      valueField="id"
                      textField="name"
                    />
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    ![10, 11, 13, 14].includes(
                      fieldList[activeIndex].field_type_id
                    )
                  "
                >
                  <b-col sm="12" md="12" lg="6">
                    <InputText
                      isRequired
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                        isSubmit && !fieldList[activeIndex].label_admin
                          ? 'error'
                          : '',
                      ]"
                      textFloat="Label For Admin"
                      v-model="fieldList[activeIndex].label_admin"
                      placeholder="Label For Admin"
                      type="text"
                      :v="$v.fieldList.$each.$iter[activeIndex].label_admin"
                      :isValidate="
                        $v.fieldList.$each.$iter[activeIndex].label_admin.$error
                      "
                  /></b-col>
                  <b-col sm="12" md="12" lg="6">
                    <InputText
                      isRequired
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                        isSubmit && !fieldList[activeIndex].label_customer
                          ? 'error'
                          : '',
                      ]"
                      textFloat="Label For Customer"
                      v-model="fieldList[activeIndex].label_customer"
                      placeholder="Label For Customer"
                      type="text"
                      :v="$v.fieldList.$each.$iter[activeIndex].label_customer"
                      :isValidate="
                        $v.fieldList.$each.$iter[activeIndex].label_customer
                          .$error
                      "
                    />
                  </b-col>
                </b-row>

                <b-row
                  v-if="
                    ![10, 11, 13, 14].includes(
                      fieldList[activeIndex].field_type_id
                    )
                  "
                >
                  <b-col sm="12" md="12" lg="6">
                    <InputText
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                        isSubmit && !fieldList[activeIndex].validate_text_admin
                          ? 'error'
                          : '',
                      ]"
                      textFloat="Validate text"
                      v-model="fieldList[activeIndex].validate_text_admin"
                      placeholder="Validate text"
                      type="text"
                  /></b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" lg="6">
                    <InputText
                      isRequired
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                        isSubmit && !fieldList[activeIndex].name ? 'error' : '',
                      ]"
                      textFloat="Name"
                      v-model="fieldList[activeIndex].name"
                      :disabled="
                        Boolean(fieldList[activeIndex].is_default_field)
                      "
                      placeholder="Name"
                      type="text"
                      :v="$v.fieldList.$each.$iter[activeIndex].name"
                      :isValidate="
                        $v.fieldList.$each.$iter[activeIndex].name.$error
                      "
                  /></b-col>
                </b-row>
                <b-row
                  v-if="[10, 11].includes(fieldList[activeIndex].field_type_id)"
                >
                  <b-col sm="12" md="12" lg="6">
                    <InputText
                      :class="[
                        'text-float-normal question-name-input margin-input-5',
                        isSubmit && !fieldList[activeIndex].label_admin
                          ? 'error'
                          : '',
                      ]"
                      isRequired
                      textFloat="Question"
                      v-model="fieldList[activeIndex].label_admin"
                      placeholder="Question"
                      type="text"
                      :v="$v.fieldList.$each.$iter[activeIndex].label_admin"
                      :isValidate="
                        $v.fieldList.$each.$iter[activeIndex].label_admin.$error
                      "
                  /></b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                    <FieldTextDetail
                      v-if="fieldList[activeIndex].field_type_id == 1"
                      :item="fieldList[activeIndex]"
                      input-class="custom-text-input" />
                    <FieldChoiceDetail
                      v-if="
                        fieldList[activeIndex].field_type_id >= 2 &&
                        fieldList[activeIndex].field_type_id <= 4
                      "
                      :item="fieldList[activeIndex]"
                      :isDisable="fieldList[activeIndex].id == 5" />
                    <DateInput
                      v-if="fieldList[activeIndex].field_type_id == 5" />

                    <ModalSelectBranch
                      v-if="
                        fieldList[activeIndex].field_type_id == 6 &&
                        fieldList[activeIndex].branchList.length
                      "
                      :list="fieldList[activeIndex].branchList"
                      @selectBranch="selectBranch"
                      :field="fieldList[activeIndex]"
                      text="Select Branch"
                      :id="`${fieldList[activeIndex].id}`"
                      :BranchSelectList="
                        fieldList[activeIndex].select_branch || []
                      " />
                    <Date
                      v-if="fieldList[activeIndex].field_type_id == 7"
                      class="" />

                    <InputTextArea
                      v-if="fieldList[activeIndex].field_type_id == 12"
                      textFloat=""
                      type="text"
                      class="mt-3 custom-input"
                      placeholder=""
                      rows="3"
                  /></b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
    <FooterAction routePath="/setting/ticket" @submit="sendForm(0)" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Date from "@/components/form/preview/Date";
import DateInput from "@/components/form/preview/DateInput";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import FieldChoiceDetail from "@/components/ticket/FieldChoiceDetail";
import FieldTextDetail from "@/components/ticket/FieldTextDetail";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import { requiredIf, required } from "vuelidate/lib/validators";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    draggable,
    Date,
    DateInput,
    ModalSelectBranch,
    OtherLoading,
    FieldChoiceDetail,
    FieldTextDetail,
    InputText,
    InputTextArea,
    InputSelect,
  },
  validations() {
    return {
      fieldList: {
        $each: {
          label_admin: {
            required: requiredIf(function (item) {
              return [13, 14].includes(item.field_type_id) ? false : true;
            }),
          },
          label_customer: {
            required: requiredIf(function (item) {
              return [10, 11, 13, 14].includes(item.field_type_id)
                ? false
                : true;
            }),
          },
          name: {
            required,
          },
        },
      },
    };
  },
  data() {
    return {
      value: [],
      isLoading: true,
      isBusy: false,
      isSubmit: false,
      questionList: [],
      activeIndex: 0,
      questionProfileIndex: null,
      showProfilePanel: true,
      branchList: [],
      fieldTypes: [
        { id: 1, name: "Field" },
        { id: 2, name: "Checkbox" },
        { id: 3, name: "Multiple Choice" },
        { id: 4, name: "Dropdown" },
        { id: 5, name: "DateTime" },
        { id: 6, name: "Branch dropdown" },
        { id: 7, name: "Date" },
        { id: 8, name: "Image" },
        { id: 10, name: "Title" },
        { id: 11, name: "Subtitle" },
        { id: 12, name: "Text Area" },
        { id: 13, name: "Space" },
      ],
      activeAddition: false,

      fieldList: [],
    };
  },
  computed: {},

  async created() {
    this.getField();
    this.getFieldType();
  },
  methods: {
    changeDrag(value) {
      this.activeIndex = value.newIndex;
    },
    async getFieldType() {
      await this.$store.dispatch("getFieldTypes");
      let data = this.$store.state.form.fieldtype;
      if (data.result == 1) {
        this.fieldTypes = data.detail.fieldTypes.filter((type) => type.id != 9);
      }
    },

    async getField() {
      const { data } = await this.axios(`/ticket/list_field`);

      this.fieldList = [
        ...data.detail.default_fields,
        ...data.detail.custom_fields,
      ].map((el) => {
        return {
          ...el,
        };
      });
      for (const field of this.fieldList) {
        if (field.field_type_id === 6) {
          await this.getBranchList(field.branch_group_id ?? 0, field);
        }
      }

      this.isLoading = false;
    },

    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index].name;
        }
    },

    addField() {
      this.fieldList.push({
        id: undefined,
        field_type_id: 1,
        is_default_field: 0,
        validate_text_admin: null,
        validate_text_customer: null,
        label_admin: null,
        label_customer: null,
        is_require_submit: 1,
        is_require_close: 1,
        is_customer_view: 1,
        is_customer_edit: 1,
        is_customer_require_submit: 1,
        img: "",
        select_branch: [],
        field_choice: [],
        branchList: [],
        field_condition: [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ],
        branch_group_id: 0,
      });
      this.activeIndex = this.fieldList.length - 1;
      this.activeAddition = true;
    },

    async sendForm() {
      this.$v.fieldList.$touch();
      if (this.$v.fieldList.$error) {
        return;
      }

      const default_fields = this.fieldList.filter(
        (el) => el.is_default_field === 1
      );
      const custom_fields = this.fieldList.filter(
        (el) => el.is_default_field === 0
      );

      let payload = {
        default_fields,
        custom_fields,
      };
      this.$bus.$emit("showLoading");
      const { data } = await this.axios.post(`/ticket/field`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.$router.push("/setting/ticket");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },

    handleClickActive(val, index) {
      this.activeAddition = val;
      this.activeIndex = index;
    },
    deleteQuestion() {
      this.fieldList.splice(this.activeIndex, 1);
      this.activeIndex = this.fieldList.length ? this.fieldList.length - 1 : 0;
    },
    async getBranchList(id, field) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
    },

    async changeFieldType(value) {
      if (value == 1) {
        this.fieldList[this.activeIndex].field_condition = [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ];
        this.fieldList[this.activeIndex].field_choice = [];
      } else if (value >= 2 && value <= 4) {
        this.fieldList[this.activeIndex].field_condition = [];
        this.fieldList[this.activeIndex].field_choice = [
          { name: "choice 1", sort_order: 0, isDefault: 0 },
        ];
      } else if (value == 5 || value == 7) {
        this.fieldList[this.activeIndex].field_condition = [];
        this.fieldList[this.activeIndex].field_choice = [{ name: "" }];
      } else if (value == 6) {
        this.fieldList[this.activeIndex].field_choice = [];
        this.fieldList[this.activeIndex].field_condition = [];
        this.fieldList[this.activeIndex].branchList = [];
        this.fieldList[this.activeIndex].select_branch = [];
        const idBranch = this.fieldList[this.activeIndex].branch_group_id || 0;
        await this.getBranchList(idBranch, this.fieldList[this.activeIndex]);
      } else if ([10, 11, 13, 14].includes(value)) {
        this.fieldList[this.activeIndex].is_require_submit = 0;
        this.fieldList[this.activeIndex].is_require_close = 0;
        this.fieldList[this.activeIndex].is_customer_view = 0;
        this.fieldList[this.activeIndex].is_customer_edit = 0;
        this.fieldList[this.activeIndex].is_customer_require_submit = 0;
      } else {
        this.fieldList[this.activeIndex].field_condition = [];
        this.fieldList[this.activeIndex].field_choice = [];
      }
    },
    selectBranch(value) {
      this.fieldList[this.activeIndex].select_branch = value;
      this.$forceUpdate();
    },
    labelDescription(item) {
      if ([2, 3, 4].includes(item.field_type_id)) {
        return item.field_choice.map((el) => el.name).join(", ");
      } else return item.label_admin;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tabs {
  min-height: 66px;
}
.text-normal {
  color: #5b5b5b;
}
.text-light-grey {
  color: #9a9a9a;
}
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
.header-title {
  display: flex;
  padding: 8px 16px;
  margin-top: 1px;
  justify-content: space-between;
  align-items: center;
}
.add-btn {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.add-btn:hover {
  background-color: var(--primary-color);
  color: white;
  opacity: 0.8;
}

.question-panel {
  height: calc(100vh - 380px);
  overflow-x: auto;
  background-color: white;
  padding: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

.question-card {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);
  border-left: 5px solid transparent;
  background-color: white;
  border-radius: 4px;
  text-overflow: ellipsis;
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 78px;

  .question-card-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .question-card-title {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &.active {
    border-left: 5px solid var(--primary-color);
  }
}

.question-detail-wrapper {
  height: calc(100vh - 380px);
  overflow-x: auto;
  background-color: white;
  padding: 16px;
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;

  .question-detail {
    display: flex;
    gap: 8px;
  }

  .question-detail-form {
    width: 100%;
  }
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}
.margin-input {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.margin-input-5 {
  margin-bottom: 5px !important;
}
::v-deep .question-name-input {
  font-size: 16px;
  &.error input {
    border: 1px solid red;
  }
}
.dropdown-input-field ::v-deep button {
  overflow: hidden;
  color: var(--font-main-color);
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  text-align: left;
  padding: 0.375rem 0.75rem;
  background: white;
}
::v-deep .multiselect__single {
  font-size: 14px !important;
}

::v-deep .select-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 767px) {
  .question-detail-wrapper {
    height: 100%;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
